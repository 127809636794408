<template>
  <div class="stepContent commonClass">
    <h3 class="titHead">供试品配制</h3>
    <div class="detail">
      <el-form ref="solutionCompound" size="small" :model="solutionCompound" class="addForm" label-suffix=":"
               label-width="90px" :disabled="disabled">
        <h3 class="tit">实验分组</h3>
        <el-form-item label="使用仪器">
          <div v-for="(obj,ind) in solutionCompound.instrument" :key="ind" class="modeFor">
            <el-input v-model="obj.instrumentName" :placeholder='"请输入仪器"+(ind+1)+"名称"' clearable
                      class="smallWidth"></el-input>
            <el-input v-model="obj.instruModel" :placeholder='"请输入仪器"+(ind+1)+"型号"' clearable
                      class="smallWidth"></el-input>
            <el-button size="small" plain class="delIns" icon="el-icon-close" @click="delIns(ind)"></el-button>
          </div>
          <el-button icon="el-icon-plus" class="addInstru" @click="addInstru">添加仪器</el-button>
        </el-form-item>
        <el-form-item label="配制品类别">
          <el-checkbox-group v-model="solutionCompound.sortArr" class="checkBox"
                             @change="(()=>{getCheckedVacc(solutionCompound)})">
            <el-checkbox label="供试品"></el-checkbox>
            <el-checkbox label="对照品"></el-checkbox>
            <el-checkbox label="溶媒"></el-checkbox>
          </el-checkbox-group>
        </el-form-item>
        <h3 class="tit">供试品/对照品/配制所用介质</h3>
        <el-table
            :data="solutionCompound.solutionMediumList" border
            :row-class-name="tableRowClassName"
            class="tableMember"
            :header-cell-style="{'background':'rgba(213, 234, 255, 0.5)','color': '#006DFF','fontWeight':'500'}"
            style="width: calc(100% - 12px);font-size:14px;color:#333">
          <el-table-column label="介质名称" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="mediumObj.name" size="small" v-if="scope.$index==0"
                        placeholder="介质名称"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="介质名称" v-model="scope.row.name"></el-input>
                </template>
                <span v-else>{{ scope.row.name }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="批号">
            <template slot-scope="scope">
              <el-input v-model="mediumObj.batchNumber" size="small" v-if="scope.$index==0"
                        placeholder="批号"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="批号" v-model="scope.row.batchNumber"></el-input>
                </template>
                <span v-else>{{ scope.row.batchNumber }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="性状" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="mediumObj.character" size="small" v-if="scope.$index==0"
                        placeholder="性状"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="性状" v-model="scope.row.character"></el-input>
                </template>
                <span v-else>{{ scope.row.character }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="保存条件" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="mediumObj.storageCondition" size="small" v-if="scope.$index==0"
                        placeholder="保存条件"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="保存条件" v-model="scope.row.storageCondition"></el-input>
                </template>
                <span v-else>{{ scope.row.storageCondition }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="有效期" width="160">
            <template slot-scope="scope">
              <el-date-picker
                  v-if="scope.$index==0" size="small"
                  v-model="mediumObj.validUntil" style="width: 100%;"
                  type="date"
                  placeholder="有效期">
              </el-date-picker>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-date-picker
                      size="small" style="width: 100%;"
                      v-model="scope.row.validUntil"
                      type="date"
                      placeholder="有效期">
                  </el-date-picker>
                </template>
                <span v-else>{{ scope.row.validUntil | formatDay }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="提供单位" show-overflow-tooltip>
            <template slot-scope="scope">
              <el-input v-model="mediumObj.supplier" size="small" v-if="scope.$index==0"
                        placeholder="提供单位"></el-input>
              <template v-else>
                <template v-if="scope.row.show">
                  <el-input size="small" placeholder="提供单位" v-model="scope.row.supplier"></el-input>
                </template>
                <span v-else>{{ scope.row.supplier }}</span>
              </template>
            </template>
          </el-table-column>
          <el-table-column label="操作" width="150">
            <template slot-scope="scope">
              <div v-if="scope.$index==0" class="addColor">
                <el-button type="text" @click="add">添加</el-button>
                <el-button type="text" @click="reset">重置</el-button>
              </div>
              <div v-else class="editColor">
                <template v-if="state==0">
                  <el-button type="text" v-if="!scope.row.show" @click="edit(scope.row,scope.$index)">修改</el-button>
                  <el-button type="text" v-else @click="save(scope.row)">保存</el-button>
                </template>
                <el-button type="text" v-else @click="edit(scope.row,scope.$index)">{{ scope.row.show ? '保存' : "修改" }}
                </el-button>
                <el-button type="text" @click="copy(scope.row,scope.$index)">复制</el-button>
                <el-button type="text" @click="deleteA(scope.row.id,scope.$index)">删除</el-button>
              </div>
            </template>
          </el-table-column>
        </el-table>
        <h3 class="tit">配制方法</h3>
        <div class="desc">
          <p class="smallTit">1. 溶媒配制：</p>
          <el-input v-model="solutionCompound.solvent" type="textarea" rows="4" size="small"
                    placeholder="请输入配制方法"></el-input>
        </div>
        <div class="desc">
          <p class="smallTit">2. 供试品配制：（在生物安全柜中，现用现配）</p>
          <el-input v-model="solutionCompound.test" placeholder="请输入备配制方法"
                    type="textarea" rows="4" size="small" clearable
          ></el-input>
        </div>
        <div class="desc">
          <p class="smallTit">3. 对照品配制：</p>
          <el-input v-model="solutionCompound.reference" type="textarea" rows="4" size="small"
                    placeholder="请输入备配制方法"></el-input>
        </div>
        <h3 class="tit">供试品</h3>
        <div class="flex head">
          <el-form-item label="配制条件">
            <el-input v-model="solutionCompound.testCondition" clearable size="small"
                      placeholder="请输入配制条件"></el-input>
          </el-form-item>
          <el-form-item label="有效期">
            <el-date-picker
                size="small"
                v-model="solutionCompound.testUsefulLife" style="width: 100%;"
                type="date"
                placeholder="请输入有效期">
            </el-date-picker>
          </el-form-item>
        </div>
        <el-form-item label="剩余液处理方法">
          <el-input v-model="solutionCompound.testResidueHandle" type="textarea" rows="4" size="small"
                    placeholder="请输入剩余液处理方法"></el-input>
        </el-form-item>
        <h3 class="tit">指定复核人</h3>
        <el-form-item label="复核人员">
          <el-radio-group v-model="solutionCompound.reviewName" class="radioGroup">
            <el-radio v-for="item in userList" :label="item.name" :key="item.userId"
                      @click.native="getAppId(item.userId,item.name)"
            > {{ item.name }}
            </el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
      <div class="fromSave">
        <template v-if="state!=2">
          <el-button type="primary" size="small" @click="submitCom('solutionCompound',1)">配制完成
          </el-button>
          <el-button plain type="primary" size="small" @click="submitCom('solutionCompound',0)">保存
          </el-button>
        </template>
        <el-button plain type="primary" size="small" @click="$emit('changePageGroup',false,1)">导出预览</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import moment from "moment";

let top = {
  name: "",
  batchNumber: "",
  character: "",
  storageCondition: "",
  validUntil: "",
  supplier: ""
}
export default {
  name: "preparation",
  props: ['id', 'status'],
  data() {
    return {
      solutionCompound: {
        sort: "",
        sortArr: [],
        instrument: [],
        solutionMediumList: [],
        solvent: "",
        reference: "",
        test: "",
        testCondition: "",
        testUsefulLife: "",
        testResidueHandle: "",
        reviewer: null,
        reviewName: ""
      },
      userList: [],
      mediumObj: {
        name: "",
        batchNumber: "",
        character: "",
        storageCondition: "",
        validUntil: "",
        supplier: ""
      },
      state: null,
      disabled: false
    }
  },
  created() {
    this.solutionCompound.solutionMediumList.unshift(top)
    this.solutionCompound.solutionMediumList.map(i => {
      i.show = false
      return i
    })
  },
  mounted() {
    this.$get('/subject/member/list/' + this.id).then(res => {
      if (res.status == 200) {
        this.userList = res.data
      }
    })
    if (this.status > 28) {
      this.getSaveData()
    }
  },
  methods: {
    addInstru() {
      this.solutionCompound.instrument.push({
        instrumentName: "",
        instruModel: ""
      })
    },
    delIns(index) {
      this.solutionCompound.instrument.splice(index, 1)
    },
    // 指定复核人员
    getAppId(id, name) {
      this.solutionCompound.reviewer = id
      this.solutionCompound.reviewName = name
    },
    // 设置第一行样式
    tableRowClassName({row, rowIndex}) {
      if (rowIndex === 0) {
        return 'formAdd';
      }
      return '';
    },
    // 获取已经提交的数据
    getSaveData() {
      this.$get('/solution/compound/' + this.id).then(res => {
        if (res.status == 200) {
          this.state = res.data.state
          let newArr = {}
          newArr = {
            sortArr: res.data.sort.split(","),
            solutionMediumList: res.data.solutionMediumList,
            instrument: JSON.parse(res.data.instrument),
            solvent: res.data.solvent,
            reference: res.data.reference,
            test: res.data.test,
            testCondition: res.data.testCondition,
            testUsefulLife: moment(res.data.testUsefulLife).format("YYYY-MM-DD"),
            testResidueHandle: res.data.testResidueHandle,
            reviewer: res.data.reviewer,
            reviewName: res.data.reviewName
          }
          newArr.solutionMediumList.unshift(top)
          this.solutionCompound = newArr

          if (this.state == 1 || this.state == 2) {
            this.disabled = true
          }
        }
      })
    },
    // 添加
    add() {
      if ($.trim(this.mediumObj.name).length === 0) {
        this.$message.warning("介质名称不能为空");
        return;
      }
      if ($.trim(this.mediumObj.batchNumber).length === 0) {
        this.$message.warning("批号不能为空");
        return;
      }
      if ($.trim(this.mediumObj.character).length === 0) {
        this.$message.warning("性状不能为空");
        return;
      }
      if ($.trim(this.mediumObj.storageCondition).length === 0) {
        this.$message.warning("保存条件不能为空");
        return;
      }
      if ($.trim(this.mediumObj.validUntil).length === 0) {
        this.$message.warning("有效期不能为空");
        return;
      }
      if ($.trim(this.mediumObj.supplier).length === 0) {
        this.$message.warning("提供单位不能为空");
        return;
      }

      // this.mediumObj.validUntilStr = new Date(this.mediumObj.validUntil)
      if (this.state == null) {
        this.solutionCompound.solutionMediumList.push(this.mediumObj)
      } else {
        this.addTable(this.mediumObj)
      }
      this.mediumObj = {
        name: "",
        batchNumber: "",
        character: "",
        storageCondition: "",
        validUntil: "",
        supplier: ""
      }
    },
    addTable(medium) {
      medium.subId = Number(this.id)
      this.$postJson('/solution/medium', medium).then(res => {
        if (res.status == 201) {
          this.$message.success("添加成功")
          this.$nextTick(() => {
            this.getMediumTab()
          })
        }
      })
    },
    // 重置
    reset() {
      this.mediumObj = {
        name: "",
        batchNumber: "",
        character: "",
        storageCondition: "",
        validUntil: "",
        supplier: ""
      }
    },
    // 编辑
    edit(row, index) {
      row.show = !row.show
      this.$set(this.solutionCompound.solutionMediumList, index, row)
    },
    // 复制
    copy(val, index) {
      if (this.state == null) {
        this.solutionCompound.solutionMediumList.splice(index, 0, JSON.parse(JSON.stringify(val)))
      } else {
        this.$delete(val, 'id')
        this.addTable(val)
      }
    },
    // 获取介质表
    getMediumTab() {
      this.$get('solution/medium/' + this.id).then(res => {
        if (res.status == 200) {
          this.solutionCompound.solutionMediumList = res.data
          this.solutionCompound.solutionMediumList.unshift(top)
        }
      })
    },
    // 保存
    save(row) {
      this.$putJson('/solution/medium', row).then(res => {
        if (res.status == 200) {
          this.$message.success('修改成功')
          this.$nextTick(() => {
            this.getMediumTab()
          })
        }
      })
    },
    // 删除
    deleteA(id, index) {
      if (this.state == null) {
        this.solutionCompound.solutionMediumList.splice(index, 1)
      } else {
        this.$del('/solution/medium/' + id).then(res => {
          if (res.status == 200) {
            this.$message.success("删除成功")
            this.$nextTick(() => {
              this.getMediumTab()
            })
          }
        })
      }
    },
    // 获取类别
    getCheckedVacc(row) {
      row.sort = row.sortArr.join(",");
    },
    // 提交
    submitCom(solutionCompound, state) {
      this.$refs[solutionCompound].validate((valid) => {
        if (valid) {
          if (this.solutionCompound.solutionMediumList.length === 1) {
            this.$message.warning("请填写所需要的的介质")
            return false;
          }
          if ($.trim(this.solutionCompound.reviewer).length === 0) {
            this.$message.warning("请选择复核人")
            return false;
          }

          this.solutionCompound.subId = Number(this.id)
          this.solutionCompound.state = state
          this.solutionCompound.solutionMediumList.splice(0, 1)
          this.solutionCompound.instrument = JSON.stringify(this.solutionCompound.instrument)
          // this.solutionCompound.testUsefulLifeStr = new Date(this.solutionCompound.testUsefulLife)
          let url;
          if (this.state == null) {
            url = this.$postJson('/solution/compound', this.solutionCompound)
          } else {
            this.solutionCompound.testUsefulLife = new Date(this.solutionCompound.testUsefulLife)
            this.$delete(this.solutionCompound, 'solutionMediumList')
            url = this.$putJson('/solution/compound', this.solutionCompound)
          }
          url.then(res => {
            if (res.status == 201 || res.status == 200) {
              this.$message.success("提交成功")
              this.$router.go(-1)
            }
          })
        }
      })
    }
  },
}
</script>

<style lang="scss" scoped>
@import "../../../../assets/css/exper/experiDetail";
</style>
